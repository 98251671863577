import { Container, SwipeableDrawer, Typography, IconButton, Paper, Modal, Tooltip, styled, Button } from "@mui/material"
import { Account } from "./Account"
import Box from "./Box"
import { useDispatch, useSelector } from "react-redux"
import { OtherHouses, Menu, Extension, House, Login, LoginOutlined, LoginTwoTone, AccountBoxOutlined, AccountCircle } from "@mui/icons-material"
import { collection, getDocs, query, where } from "firebase/firestore"
import { useState } from "react"
import { useEffect } from "react"
import { setProperties } from "../redux/slice"
import ViewProperties from "./ViewProperties"
import { Global } from '@emotion/react';
import { grey, red } from "@mui/material/colors"
import { useNavigate } from "react-router-dom"
// import logo from "../public/brandmark.png";


const style = {
    position: 'absolute',
    top: '50px',
    right: '30px',
    height: "600px",
    // transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #434343',
    boxShadow: 24,
    p: 1,
};

const Puller = styled('div')(({ theme }) => ({
    width: 30,
    height: 6,
    backgroundColor: theme.palette.mode === 'light' ? red[300] : grey[900],
    borderRadius: 3,
    position: 'absolute',
    top: 8,
    left: 'calc(50% - 15px)',
}));
export const Layout = ({ children, showMenu = true }) => {
    const drawerBleeding = 56;
    const navigate = useNavigate();


    const authState = useSelector((state) => state);
    const dispatch = useDispatch()
    const {

        user,
        properties
    } = authState


    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    }

    const getProperties = async () => {


        const q = query(collection(firestore, "properties"), where("shareCode", "==", shareCode));

        const querySnapshot = await getDocs(q)
        console.log(querySnapshot.docs)

        var list = []

        querySnapshot.forEach((doc) => {

            const prop = { id: doc.id, ...doc.data() }




            list.push(prop)

        })

        dispatch(setProperties(list))

    }
    useEffect(() => {

    }, [])

    return (


        <Paper

            sx={{
                height: "100%",
                // width: "100%",
                background: "#2e9e6c",
                minHeight: "1000px",

                padding: "20px"
            }}
        >

            {/* <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}> */}

            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        width: `calc(40% - ${drawerBleeding}px)`,
                        height: "100%",
                        maxWidth: "475px",
                        overflow: 'visible',
                        
                    },
                }}
            />

            <SwipeableDrawer
                //    container={container}
                anchor="right"
                open={open}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                swipeAreaWidth={drawerBleeding}
                disableSwipeToOpen={false}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <Box
                    sx={{
                        
                        position: 'absolute',
                        top: -drawerBleeding,
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        visibility: 'visible',
                        bottom: 0,
                        top: 0,
                    }}
                >


                    {/* <Puller />
                <Typography>test</Typography> */}
                </Box>
                <Box
                    sx={{

                        height: '100%',
                        overflow: 'auto',
                        display: "flex",
                        flexDirection: "row",
                        background: grey[100]
                    }}
                >


                    <ViewProperties onClose={handleClose} />
                </Box>
            </SwipeableDrawer>


            {/* </Box></Modal> */}
            <Box row spaceBetween>
                <Box sx = {{width : "149px"}} />
                <img src={"https://firebasestorage.googleapis.com/v0/b/home-bliss-ext.appspot.com/o/brandmark.png?alt=media&token=7173b9f1-ea30-4f14-835c-97fa0fab4031"} width={200} height={100} />
                {showMenu && <Box
                    row
                    sx={{
                        mr : 2, 
                        alignItems: "center"
                    }}
                >

                    {user && <IconButton onClick={handleOpen}><OtherHouses color="white" /></IconButton>}

                    <Tooltip title="Install Extension">
                        <IconButton onClick={() => {
                            window.open('https://chromewebstore.google.com/detail/homebound/fmadlhmgbckdgdjegnbdidmihmpbkbga', '_blank')
                            navigate("/guide/");

                        }}><Extension color="white" /></IconButton>
                    </Tooltip>
                    {!user && <Button sx={{
                       color : "#fff",
                       textTransform : "capitalize"
                       ,mr : 3

                    }}
                    variant="text"
                    onClick={() => {
                        navigate("/account/",);


                    }}
                    >Sign In</Button>}
                    {user &&  <IconButton
                    
                    sx = {{mr : 3}}
                    onClick={() => {
                            navigate("/account/",);


                        }}><AccountCircle color="white" /></IconButton>}


                </Box>}
                {
                    !showMenu && <Box> </Box>
                }
            </Box>
            <Box
                sx={{ borderRadius: "20px", height: "100%", background: "white" }}
            >


                {children}
            </Box>
        </Paper>
    )

}