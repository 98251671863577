import React from 'react';
import { Typography, Button, Container, List, ListItem, ListItemText, IconButton } from '@mui/material';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../components/HomeComponents/Header';
import Box from '../components/Box';
import { Account } from '../components/Account';
import { Layout } from '../components/Layout';
import { useNavigate } from 'react-router-dom';

function MyComponent() {


    const authState = useSelector((state) => state);
    const dispatch = useDispatch()
    const {

        user,
        properties
    } = authState


    const signIn = () => {



        // if (isSignIn) {



        //     signInWithEmailAndPassword(auth, email, password).then(res => {


        //         getUser(res.user.uid)
        //     })
        //         .catch((error) => {
        //             const errorCode = error.code;
        //             const errorMessage = error.message;
        //             console.log(errorMessage)


        //             var message = ''
        //             if (errorMessage == "auth/email-already-in-use") {

        //                 message = "It looks like you already have an account"
        //             }
        //             else if (errorMessage == "auth/invalid-credential" || errorMessage == "auth/invalid-email" || errorMessage == "auth/wrong-password") {
        //                 message = "Your email or password is invalid"
        //             }
        //             else {
        //                 message = "Unable to sign in. Please check your credentials again"
        //             }

        //             dispatch(setSnackMessage(message))
        //         })
        // }
        // else {
        //     try {





        //         createUserWithEmailAndPassword(auth, email, password)
        //             .then((userCredential) => {
        //                 // Signed up 
        //                 const user = userCredential.user;

        //                 saveUser(user.uid)
        //                 // ...
        //             })
        //             .catch((error) => {
        //                 const errorCode = error.code;
        //                 const errorMessage = error.message;
        //                 console.log(errorMessage)

        //                 var message = ''


        //                 if (errorMessage == "auth/email-already-in-use") {
        //                     message = "It looks like you already have an account"
        //                 }
        //                 else if (errorMessage == "auth/invalid-email" || errorMessage == "auth/wrong-password") {
        //                     message = "Your email or password is invalid"
        //                 }
        //                 else {
        //                     message = "Unable to sign in. Please check your credentials again"
        //                 }

        //                 dispatch(setSnackMessage(message))



        //                 // ..
        //             });

        //         // sendSignInLinkToEmail(auth, email, actionCodeSettings)
        //         //     .then(() => {
        //         //         // The link was successfully sent. Inform the user.
        //         //         // Save the email locally so you don't need to ask the user for it again
        //         //         // if they open the link on the same device.
        //         //         window.localStorage.setItem('emailForSignIn', email);
        //         //         // ...
        //         //     })
        //         //     .catch((error) => {
        //         //         const errorCode = error.code;
        //         //         const errorMessage = error.message;

        //         //         console.log(errorMessage)
        //         //         // ...
        //         //     });


        //         // You can redirect the user or perform any action upon successful login
        //     } catch (error) {
        //         setError(error.message);
        //     }
        // }

    }

const navigate = useNavigate()
    return (
        <Layout>
            {/* <Box row spaceBetween sx = {{background : "#009963", alignItems :"center", px : 1}}>
                <Box/>
        <img src={"https://firebasestorage.googleapis.com/v0/b/home-bliss-ext.appspot.com/o/brandmark.png?alt=media&token=7173b9f1-ea30-4f14-835c-97fa0fab4031"} width={200} height={100} />
        <Box row>

        {user && <SignIn />}
        </Box>
        
        </Box> */}
            {/* <Header/> */}

            <Box sx={{
                display: "flex",
                m: 2,
                // background : "green" 
            }}>
                <Box
                // sx = {{ maxWidth : "650px"}}
                >
                    <Typography variant='h2' sx={{ color: "black" }}>Using Google Sheets and WhatsApp for your home search?</Typography>
                    <Typography variant='h2' color="primary">Move to Homebound: Effortlessly manage and share properties with flatmates</Typography>
                    {/* {!user && <Button variant='contained' sx = {{ marginRight : 6,  width : "250px"}} onClick={signIn} >Create Account</Button>} */}
                    {/* {!user && <Account />} */}
                </Box>
                {/* <Box
                    sx={{ width: "65%", height: "420px", background: "black" }}
                >
                    <img width={100} src="/static/media/homebound-white.b3a29d6895024c2ce909.png" alt="Product Image" style={{ width: '150px', marginBottom: '20px' }} />
                </Box> */}
            </Box>
            <Box>

                <Container maxWidth="lg">
                    <Box sx={{ height: "200px", pt: 12, display: "row", alignItems: "center" }}>
                        <Typography variant='h2'>Why use Homebound?</Typography>
                        <List sx={{ display: "flex" }}>
                            <ListItem>
                                <Box>
                                    <span style={{ fontSize: '45px' }}>🏡</span>
                                </Box>
                                <ListItemText>
                                    <Typography>A single place to keep track of properties</Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <Box>
                                    <span style={{ fontSize: '45px' }}>🤗</span>
                                </Box>
                                <ListItemText>
                                    <Typography>Share properties with flatmates or your partner</Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <Box>
                                    <span style={{ fontSize: '45px' }}>🤝</span>
                                </Box>
                                <ListItemText>
                                    <Typography>Compatible with Zoopla and Rightmove</Typography>
                                </ListItemText>
                            </ListItem>
                        </List>

                    </Box>
                    <Box
                        sx={{ height : "200px", display: "row", alignItems: "center", 
                            
                            // background: "green" 
                        }}
                    >
                        <Button
                        onClick={  () => navigate("/account/",)}
                        sx = {{textTransform : "capitalize"}}
                        variant='contained'
                        >Create an account?</Button>
                    </Box>
                </Container>
            </Box>
        </Layout>
    );
}

export default MyComponent;
