import React, { useState } from 'react';
import { Container, Typography, Button, Paper, List, ListItem, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AlarmIcon from '@mui/icons-material/Alarm';
import { Layout } from './Layout';
import SignIn from './SignIn';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import { useSelector } from 'react-redux';
import Box from './Box';

const Upgrade = ({ }) => {
    const theme = useTheme()
    document.title = "Upgrade Options"
    const navigator = useNavigate()
    const authState = useSelector((state) => state);
    const location = useLocation();
    const wildcard = useParams()["*"];
    console.log("wildcard", wildcard, useParams())
    const [open, setOpen] = useState(false)

    const [ownerEmail, setOwnerEmail] = useState(null)
    const [loading, setLoading] = useState(true)

    // useEffect(() => {
    //     console.log("wildc"
    //         , wildcard
    //     )

    //     const ref = doc(firestore, "homes", wildcard)


    //     getDoc(ref).then(res => {

    //         var home = res.data()
    //         console.log(home)
    //         if (home) {

    //             var owner = _.find(home.guests, g => {
    //                 return g.id == home.owner
    //             })
    //             console.log(owner)
    //             if (owner) {
    //                 setOwnerEmail(owner.name)
    //                 setLoading(false)
    //             }

    //         }

    //     })
    // }, [])



    const [price, setPrice] = useState(null)
    const prices = [
        {
            name: "Pro",
            price: "£9.99",
            duration: "3 months",
            benefit: "Perfect for when you are just starting your search"
            , link: "https://pay.homebound.site/b/4gwaG16lzefq3As00a"
        }, {
            name: "Lifetime Access",
            price: "£39.00",
            duration: "Lifetime",
            benefit: "Perfect for investors"
            , link: "https://pay.homebound.site/b/5kAaG1aBP6MY7QIcMX"
        }
    ]
    return (
        <Layout showMenu={false}>




            <Box

                sx={{ alignItems: "center", height: "700px" }}
            >
                <Box>
                    <Typography variant='h3'>
                        Premium Perks
                    </Typography>
                    <List dense>

                        <ListItem>
                            <ListItemText
                                primary="Share Properties with family and flatmates"

                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Unlimited Properties"

                            />
                        </ListItem>


                        <ListItem>
                            <ListItemText
                                primary="Activate your membership anytime"

                            />

                        </ListItem>

                    </List>
                </Box>

                <Box>
                    <Box row
                        sx={{

                        }}
                    >

                        {prices.map(p => {

                            return (
                                <Paper
                                    key={p.name}
                                    sx={{
                                        display: "flex", background: "red", ml: 2, mr: 2,
                                        width: "210px", height: "280px", mb: 3, borderRadius: "8px",
                                        borderTopColor: p.link == price ? theme.palette.primary.main : "#eeeeee",
                                        borderTopStyle: "solid", borderTopWidth: "2.5px"
                                    }}

                                    onClick={() => setPrice(p.link)}
                                >
                                    <Box sx={{
                                        alignItems: "center",
                                        background: "green",

                                    }}>
                                        <Box sx={{ width: "100%", alignItems: "center", }} >
                                            <Typography variant='h4'>
                                                {p.name}
                                            </Typography>
                                            <Typography variant='h6' color="accent">
                                                {p.price}

                                            </Typography>
                                            <Typography

                                            >
                                                {p.duration}
                                            </Typography>
                                            <Typography variant='caption' sx={{ width: "100%" }}>
                                                {p.benefit}
                                            </Typography>
                                        </Box>
                                        <Box
                                        // sx={{ width: "30%", alignItems: "end" }}
                                        >



                                        </Box>
                                    </Box>
                                </Paper>)

                        })}
                    </Box>

                </Box>




                <Box row spaceBetween

                    sx={{

                        left: 0, right: 0, bottom: 0,
                        marginBottom: "3px"
                    }}>


                    <Button

                        sx={{ width: "190px" }}
                        variant="contained"
                        onClick={() => {
                            window.open(price)
                        }}>
                        Go to Checkout
                    </Button>
                </Box>
            </Box>
        </Layout>

    );
};

export default Upgrade;
