import logo from './logo.svg';
import './App.css';
import MovingToOxford from './blogPages/MovingToOxford';
import Details from './pages/details';
import TopPlacesInOxfordToLive from './blogPages/TopPlacesInOxfordToLive';
import { Layout } from './components/Layout';
import {
  createBrowserRouter,
  RouterProvider,
  redirect,
  Navigate
} from "react-router-dom";
import Home from './pages/home';
// import Home from './components/HomeComponents/Home';
import { useEffect } from 'react';
import { closSnackbar, setHomes, setUser, setUserID } from './redux/slice';
import { useDispatch, useSelector } from 'react-redux';
import { collection, doc, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { firestore } from './firebaseConfig';
import { Snackbar } from '@mui/material';
import NewInvite from './components/NewInvite';
import { Account } from './components/Account';
import HowTo from './components/HowTo';
import Upgrade from './components/Upgrade';


const router = createBrowserRouter([
  {
    path: "/",
    element: <div><Home /></div>,
  },
  {
    path: "/details/*",
    element: <div><Details /></div>,
  },
  {
    path: "/properties/*",
    element: <div><Details /></div>,
  },
  {
    path: "/account/*",
    element: <div><Account /></div>,
  },
  {
    path: "/invite/*",
    element: <div><NewInvite  /></div>,
  },
  {
    path: "/*",
    element: <div><Details /></div>,
  },
  {
    path: "/guide",
    element: <div><HowTo title="Install Guide" /></div>,
  },
  {
    path: "/upgrade",
    element: <div><Upgrade title="Install Guide" /></div>,
  },
]);
function App() {

  const dispatch = useDispatch()
  const reduxState = useSelector((state) => state);

  const {
    openSnack, snackMessage, userID, 
  } = reduxState

  const handleClose = () => {
    dispatch(closSnackbar())
  }
  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        const uid = user.uid;

        const ref = doc(firestore, "users", uid);

        getDoc(ref).then(res => {

          const user = { id: res.id, ...res.data() }

          dispatch(setUser(user))
          dispatch(setUserID(uid))
          getHomeGuests(uid)

        })
        // ...
      } else {
        dispatch(setUser(null))
        dispatch(setUserID(null))
        // User is signed out
        // ...
      }
    });

    getHomeGuests()

  }, [])


  const getHomeGuests = async (uid) => {
    uid = uid || userID

    if(uid)
    {
      const q = query(collection(firestore, "homes"), where("guestIDs", "array-contains", uid));

      const querySnapshot = await getDocs(q)
  
      var tempGuests = []
      querySnapshot.forEach((doc) => {
  
        tempGuests.push({
          id: doc.id,
          ...doc.data()
        })
      })
  
      dispatch(setHomes(tempGuests))
  
    }
   


  }
  return (
    <div className="App">

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openSnack}
        autoHideDuration={2500}
        onClose={handleClose}
        message={snackMessage}
        sx={{

        }}
      //   action={action}
      />
      <RouterProvider router={router} />


    </div>
  );
}

export default App;
